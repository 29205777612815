<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('Cert') }}
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          :disabled="!selected.length"
          @click="sendPrint"
        >
          <v-icon class="me-1">
            {{ icons.mdiPrinter }}
          </v-icon>
          <span class="d-none d-sm-block">{{ $t('print_medical_certificate') }}</span>
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          outlined
          icon
          :disabled="!selected.length"
          @click="sendPrint"
        >
          <v-icon>
            {{ icons.mdiPrinter }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-dialog
            ref="date"
            v-model.trim="isShowDate"
            :return-value.sync="cert_create"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="cert_create"
                outlined
                readonly
                dense
                :label="$t('date')"
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <v-tooltip
                    color="#212121"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="cert_create = ''"
                      >
                        {{ icons.mdiCalendarBlank }}
                      </v-icon>
                    </template>
                    <span>{{ $t("all_dates") }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="cert_create"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.date.save(cert_create)"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="isShowDate = false"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          class="py-0"
          md="6"
          lg="4"
        >
          <v-select
            v-model="cert_status_id"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        v-model="selected"
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        item-key="cert_id"
        show-select
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.cert_id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.cert_create`]="{ item }">
          <ConverseDate :date="item.cert_create" />
        </template>
        <template v-slot:[`item.cert_status_id`]="{ item }">
          <StatusBlock :status="item.cert_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="sendPrintOneItem(item.cert_id)"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("print") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item;isEditCertDoctor = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="cert_id = item.cert_id;cert_status_id_update = item.cert_status_id;isUpdateStatus = true"
              >
                <v-icon>{{ item.cert_status_id == 1?icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.cert_status_id == 1?$t("suspend"):$t('normal') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <EditCertDoctor
      v-model="isEditCertDoctor"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <UpdateStatusCertDoctor
      :id="cert_id"
      v-model="isUpdateStatus"
      :status-id="cert_status_id_update"
      @onUpdate="fetchDataTable('delete')"
    />
    <CertDoctorOptions
      :id="idList"
      v-model="isPrintCertDoctorOption"
    />
  </div>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiPrinter, mdiCalendarBlank,
} from '@mdi/js'
import useCertDoctor from './useCertDoctor'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import EditCertDoctor from './EditCertDoctor.vue'
import UpdateStatusCertDoctor from './UpdateStatusCertDoctor.vue'
import CertDoctorOptions from '@/components/prints/CertDoctorOptions.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    StatusBlock,
    ConverseDate,
    EditCertDoctor,
    UpdateStatusCertDoctor,
    Pagination,
    CertDoctorOptions,
  },
  setup() {
    return {
      ...useCertDoctor(),
      icons: {
        mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiPrinter, mdiCalendarBlank,
      },
    }
  },
}
</script>
