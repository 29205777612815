<template>
  <div>
    <v-dialog
      v-model="isUpdateStatus"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            class="me-1"
            color="warning"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>   {{ statusId==1? $t('confrimSuspendCertDoctor') :$t('cancelSuspendCertDoctor') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="loading"
            :disabled="loading"
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-update-status', false)"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiAlertOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import certDoctor from '@/api/drugAndCert/certDoctor'
import store from '@/store'

export default {
  model: {
    prop: 'isUpdateStatus',
    event: 'update:is-update-status',
  },
  props: {
    isUpdateStatus: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    statusId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const { certDoctorStatusUpdate } = certDoctor

    const updateStatus = () => {
      loading.value = true
      certDoctorStatusUpdate({
        cert_id: props.id,
        cert_status_id: props.statusId == '1' ? '2' : '1',
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-update-status', false)
        loading.value = false
        emit('onUpdate')
      })
    }

    return {
      loading,
      updateStatus,
      icons: {
        mdiAlertOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
