<template>
  <div>
    <v-dialog
      v-model="isEditCertDoctor"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('edit_cert_doctor') }}
        </v-card-title>
        <v-form
          ref="formEditCertDoctor"
          @submit.prevent="updateCertDoctor"
        >
          <v-card-text>
            <v-text-field
              v-model="dataEditLocal.cert_code"
              :label="$t('number')"
              outlined
              :rules="[required]"
              dense
            ></v-text-field>
            <v-textarea
              v-model="dataEditLocal.cert_comment"
              outlined
              class="mt-3"
              :label="$t('summary_of_opinions')"
              rows="3"
              dense
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-edit-cert-doctor',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import certDoctor from '@/api/drugAndCert/certDoctor'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  model: {
    prop: 'isEditCertDoctor',
    event: 'update:is-edit-cert-doctor',
  },
  props: {
    isEditCertDoctor: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({

      }),
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const dataEditLocal = ref({})
    const formEditCertDoctor = ref(null)

    const { certDoctorUpdate } = certDoctor

    const updateCertDoctor = () => {
      const isFormValid = formEditCertDoctor.value.validate()
      if (!isFormValid) return
      loading.value = true
      certDoctorUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-edit-cert-doctor', false)
        loading.value = false
        emit('onUpdate')
      })
    }

    watch(() => props.dataEdit, val => {
      if (val) {
        dataEditLocal.value = JSON.parse(JSON.stringify(val))
      }
    })

    return {
      loading,
      dataEditLocal,
      formEditCertDoctor,
      updateCertDoctor,
      required,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
