import basicStatus from '@/@fake-db/data/basicStatus.json'
import certDoctor from '@/api/drugAndCert/certDoctor'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import { ref, watch } from '@vue/composition-api'

export default function useCertDoctor() {
  const moment = require('moment')

  const statusList = ref(basicStatus.data)
  const cert_status_id = ref(1)
  const searchtext = ref('')
  const isShowDate = ref(false)
  const cert_create = ref(moment(new Date()).format('YYYY-MM-DD'))

  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const totalPage = ref(0)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'cert_id',
      width: 30,
    },
    {
      text: i18n.t('a_number'),
      value: 'cert_code',
      width: 100,
    },
    {
      text: i18n.t('applicant'),
      value: 'customer_fullname',
      width: 200,
    },
    {
      text: `${i18n.t('id_card')}`,
      value: 'customer_idcard',
      width: 100,
    },
    {
      text: i18n.t('request_date'),
      value: 'cert_create',
      width: 135,
    },
    {
      text: i18n.t('doctor'),
      value: 'user_fullname',
      width: 200,
    },
    {
      text: i18n.t('status'),
      value: 'cert_status_id',
      align: 'center',
      width: 100,
    },

    {
      text: i18n.t('option'),
      value: 'actions',
      align: 'center',
      width: 150,
    },
  ])
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const segmentId = ref(0)
  const loading = ref(false)

  const selected = ref([])

  const isEditCertDoctor = ref(false)
  const dataEdit = ref({})

  const isUpdateStatus = ref(false)
  const cert_id = ref('')
  const cert_status_id_update = ref('')
  const isPrintCertDoctorOption = ref(false)
  const idList = ref([])

  const { certDoctorList } = certDoctor

  const fetchDataTable = event => {
    loading.value = true
    certDoctorList({
      searchtext: searchtext.value,
      cert_status_id: cert_status_id.value,
      cert_create: cert_create.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      dataTableList.value = data
      totalDataTableList.value = count
      totalPage.value = count_of_page
      segmentId.value = segment
      if (event == 'add') {
        options.value.page = count_of_page
      } else if (event == 'delete') {
        if (+options.value.page > +count_of_page) {
          options.value.page = count_of_page
          fetchDataTable()
        }
      }
      loading.value = false
    })
  }

  watch([searchtext, cert_status_id, cert_create, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1] || newvalue[2] !== oldvalue[2]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  const sendPrintOneItem = id => {
    idList.value = []
    idList.value.push(id)
    isPrintCertDoctorOption.value = true
  }

  const sendPrint = () => {
    idList.value = selected.value.map(item => item.cert_id)
    isPrintCertDoctorOption.value = true
  }

  return {
    isUpdateStatus,
    cert_id,
    cert_status_id_update,
    isEditCertDoctor,
    dataEdit,
    selected,
    statusList,
    cert_status_id,
    searchtext,
    isShowDate,
    cert_create,
    dataTableList,
    totalPage,
    totalDataTableList,
    columns,
    options,
    footer,
    segmentId,
    loading,
    isPrintCertDoctorOption,
    idList,
    fetchDataTable,
    sendPrintOneItem,
    sendPrint,
  }
}
