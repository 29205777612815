import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const certDoctorList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'cert/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Get cert doctor list err : ', err)

      return {}
    })

  return response
}
const certDoctorGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'cert',
      param: id,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.log('Get cert doctor err : ', err)

      return {}
    })

  return response
}

const certDoctorAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'cert',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Add cert doctor err : ', err)

      return {}
    })

  return response
}

const certDoctorUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'cert',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Update cert doctor err : ', err)

      return {}
    })

  return response
}

const certDoctorStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'cert/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Update status cert doctor err : ', err)

      return {}
    })

  return response
}
export default {
  certDoctorList,
  certDoctorGet,
  certDoctorAdd,
  certDoctorUpdate,
  certDoctorStatusUpdate,
}
